<div
  [ngClass]="{ 'cursor-pointer': !isDisabled(), 'lg:w-52': !collapsed() }"
  class="flex h-[2.875rem] cursor-pointer items-center justify-center gap-2 rounded bg-[#2d324d] px-6 lg:h-auto lg:justify-between lg:p-2"
>
  <app-hes-icon
    class="flex flex-shrink-0 text-2xl leading-6 text-primary"
    [hesIcon]="{ src: 'assets/icons/company.svg' }"
  ></app-hes-icon>
  @if (!collapsed()) {
    <span
      class="capitalize-first text-sm font-medium text-primary lg:flex-grow lg:font-semibold"
    >
      {{ title() }}
    </span>
    <div class="hidden h-5 w-5 lg:block">
      <fa-icon
        [icon]="currLang === 'en' ? faChevronRight : faChevronLeft"
        class="flex-shrink-0 text-primary"
      ></fa-icon>
    </div>
  }
</div>
